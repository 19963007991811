@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

@layer base {

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    background: transparent !important;
    transition: background 5000s ease-in;
  }

  /* @font-face {
    font-family: "Lato";
    src: url("../public/fonts/Lato-Hairline.woff");
  }
  @font-face {
    font-family: "Lato";
    src: url("../public/fonts/Lato-Heavy.woff");
  }
  @font-face {
    font-family: "Lato";
    src: url("../public/fonts/Lato-Medium.woff");
  }
  @font-face {
    font-family: "Lato";
    src: url("../public/fonts/Lato-Semibold.woff");
  }
  @font-face {
    font-family: "Lato";
    src: url("../public/fonts/Lato-Thin.woff");
  }
  @font-face {
    font-family: "Lato";
    src: url("../public/fonts/Lato-Light.woff");
  }
  @font-face {
    font-family: "Lato";
    src: url("../public/fonts/Lato-Regular.woff");
  }

  @font-face {
    font-family: "Lato";
    src: url("../public/fonts/Lato-Bold.woff");
  }
  @font-face {
    font-family: "Lato";
    src: url("../public/fonts/Lato-Black.woff");
  } */

  [type="text"],
  [type="email"],
  [type="url"],
  [type="password"],
  [type="number"],
  [type="date"],
  [type="datetime-local"],
  [type="month"],
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="week"],
  [multiple],
  textarea,
  select {
    @apply transition  duration-200 px-3 h-[40px] py-1 border-[1.6px] font-medium w-full  placeholder-gray-500;
  }

  [type="text"]:focus,
  [type="email"]:focus,
  [type="url"]:focus,
  [type="password"]:focus,
  [type="number"]:focus,
  [type="date"]:focus,
  [type="datetime-local"]:focus,
  [type="month"]:focus,
  [type="search"]:focus,
  [type="tel"]:focus,
  [type="time"]:focus,
  [type="week"]:focus,
  [multiple]:focus,
  textarea:focus,
  select:focus,
  [type="text"]:focus-visible,
  [type="email"]:focus-visible,
  [type="url"]:focus-visible,
  [type="password"]:focus-visible,
  [type="number"]:focus-visible,
  [type="date"]:focus-visible,
  [type="datetime-local"]:focus-visible,
  [type="month"]:focus-visible,
  [type="search"]:focus-visible,
  [type="tel"]:focus-visible,
  [type="time"]:focus-visible,
  [type="week"]:focus-visible,
  [multiple]:focus-visible,
  textarea:focus-visible,
  select:focus-visible {
    @apply  shadow-none;
    --tw-ring-color: #f3f4f4 !important;
    --tw-ring-shadow: unset !important;
    outline: unset !important;
  }

  [type="text"].green:focus,
  [type="email"].green:focus,
  [type="url"].green:focus,
  [type="password"].green:focus,
  [type="number"].green:focus,
  [type="date"].green:focus,
  [type="datetime-local"].green:focus,
  [type="month"].green:focus,
  [type="search"].green:focus,
  [type="tel"].green:focus,
  [type="time"].green:focus,
  [type="week"].green:focus,
  [multiple].green:focus,
  textarea.green:focus,
  select.green:focus {
    @apply border-green-dark;
    --tw-ring-color: #107c10 !important;
    border-color: #107c10 !important;
  }

  [type="text"].red:focus,
  [type="email"].red:focus,
  [type="url"].red:focus,
  [type="password"].red:focus,
  [type="number"].red:focus,
  [type="date"].red:focus,
  [type="datetime-local"].red:focus,
  [type="month"].red:focus,
  [type="search"].red:focus,
  [type="tel"].red:focus,
  [type="time"].red:focus,
  [type="week"].red:focus,
  [multiple].red:focus,
  textarea.red:focus,
  select.red:focus {
    @apply border-red-dark;
    --tw-ring-color: #a80000 !important;
    border-color: #a80000 !important;
  }

  input:focus,
  select:focus {
    --tw-ring-color: transparent !important;

  }

  .Mui-error {
    color: gray !important;
  }

  select:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;

    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: gray;

    border-color: gray;
  }

  th,
  td {
    @apply break-words;
  }

  table {
    @apply w-full;
  }

  tbody {
    display: block;
    overflow: auto;
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  * {
    font-style: normal;
    font-size: 16px;
  }

  svg {
    font-size: inherit;
  }


  html {
    scroll-behavior: smooth;
  }

  body {
    @apply break-words font-montserrat !important;
  }

  a {
    @apply no-underline;
  }

  .heading-1 {
    @apply text-[26px] font-black tablet:text-[50px] md:text-[52px] lg:text-[54px];
  }

  .heading-2 {
    @apply font-semibold tablet:text-[30px] text-[22px];

  }

  .heading-3 {
    @apply text-[20px] tablet:text-[24px] font-semibold;

  }

  .heading-4 {
    @apply text-[20px] tablet:text-[22px] font-semibold;

  }

  .heading-5 {
    @apply text-[18px] tablet:text-[20px] font-semibold;

  }

  .heading-6 {
    @apply text-[16px] tablet:text-[18px] font-semibold;

  }

  p {
    @apply text-[16px] leading-6;

  }

  a {
    text-decoration: none;
    @apply text-inherit;
  }


}

@layer components {
  .disabled {
    @apply cursor-not-allowed !important;
  }

  .center {
    @apply flex justify-center items-center;
  }

  .ax-center {
    @apply absolute left-1/2 -translate-x-1/2;
  }

  .ay-center {
    @apply absolute top-1/2 -translate-y-1/2;
  }

  .a-center {
    @apply absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2;
  }

  .absolute-img {
    @apply inset-0 w-full h-full object-cover;
  }

  .btn {
    @apply h-[52px] px-10 center rounded-xl font-semibold capitalize text-gray-900 transition duration-300;
  }

  .btn-primary {
    @apply bg-primary-500 text-white;
  }

  .btn-primary:hover {
    @apply text-primary-500 bg-white;
  }

  .btn-primary-outline {
    @apply border border-primary-500 text-black;
  }

  .btn-secondary {
    @apply bg-secondary-500 text-white;
  }

  .btn-secondary:hover {
    @apply text-secondary-500 bg-white;
  }

  .btn-secondary-outline {
    @apply border border-secondary-500 text-black;
  }
}